<template>
  <div class="center">
    <md-scroll-view ref="scrollView"
                    v-show="!isNull"
                    auto-reflow
                    :scrolling-x="false"
                    @end-reached="loadMore">
      <div style="display: flex;flex-wrap: wrap;align-items: baseline;justify-content: space-between;padding:20px;">
        <div v-for="(item, index) in ghlist"
             :key="index"
             class="cdlist">
          <div class="cdcontent"
               @click="skip(item.tburl)">
            <img :src="item.logo&&item.logo.substr(0,4) == 'http'?item.logo:ossurl + item.logo"
                 alt="">
            <p>工号：{{item.cdempno}}</p>
            <p>密码：{{item.cdpasswd}}</p>
          </div>
        </div>
      </div>
      <md-scroll-view-more slot="more"
                           :is-finished="loading">
      </md-scroll-view-more>
    </md-scroll-view>
    <div class="nodata"
         v-show="isNull">
      <img class="kongimg"
           src="@/assets/image/null.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import { issueList } from '@/api/hx/simplex'
import { getStorage } from '@/lib/util'
import loadMore from '@/mixins/loadmore'// 下拉加载的
export default {
  mixins: [loadMore], // 下拉加载的
  data () {
    return {
      ghlist: [],
      pageNo: 1,
      height: '',
      isNull: true,
      total: '',
      totalPage: '',
      ossurl: '',
      loading: false,
      isFinished: false
    }
  },
  created () {
    this.ossurl = getStorage('ossurl', '')
    this.height = document.body.clientHeight
    this.$nextTick(() => {
      setTimeout(() => {
        document.querySelector('.center').style.height = `${this.height}px`
      }, 500)
    })
    this.getData()
  },
  methods: {
    async getData (isInit = true) {
      let { pageNo } = this
      if (!isInit) {
        this.pageNo = ++pageNo
        this.$refs.scrollView.finishLoadMore()
      }
      let data = {
        currentPage: this.pageNo,
        pageSize: 14,
        empno: getStorage('u_s', {}).empno
      }
      let res = await issueList(data)
      this.ghlist = isInit ? res.data.data.rows : this.ghlist.concat(res.data.data.rows)
      if (this.ghlist.length == 0) {
        this.isNull = true
      } else {
        this.isNull = false
      }
      this.total = res.data.data.total
      this.totalPage = res.data.data.totalpage
      return res
    },
    skip (val) {
      // eslint-disable-next-line no-unused-expressions
      val ? window.location.href = val : ''
    }

  }
}
</script>

<style scoped lang="stylus">
.cdlist {
  width: 48%;
  padding: 20px;
  margin-bottom: 35px;
  word-break: break-all;
  background: #fff;
  box-shadow: 5px 5px 15px 5px #ccc;
  color: #666;
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 300px;
    height: auto;
  }
}
</style>
